<template>
  <div class="dashboard">
    <div class="dashboard__container">
      <div class="dashboard__container--header flex justify-space-between align-center">
        <h1>New Jump Users</h1>
      </div>
      <div class="dashboard__container--body">
        <Loader v-if="!newUsers || newUsers.length == 0" />
        <!-- <UserTable :groupUsers="newUsers" action="groupUser" :showShiftPreference="true" /> -->

        <vue-good-table
          v-if="newUsers && newUsers.length >= 1"
          :columns="columns"
          :rows="newUsers"
          styleClass="vgt-table condensed"
          :pagination-options="{
            enabled: true,
            mode: 'records',
            perPage: 20,
          }"
          :search-options="{
            enabled: true,
            placeholder: 'Search this table',
          }"
        >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'created'">
            <span v-if="props.row.created">{{formatDate(props.row.created)}}</span>
          </span>
          <span v-else-if="props.column.field == 'photoUrl'">
            <span v-if="props.row.photoUrl">
              <img :src="(props.row.photoUrl || `https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/avatar%20copy.png?alt=media&token=966c07c4-125a-490f-81be-4e2d26bf33fa`)" alt="" style="width: 3.5rem; height:3.5rem; border-radius: 50%; padding: 0.25rem;">
            </span>
          </span>

          <span v-else-if="props.column.field == 'followUp'">
            <span v-if="props.row.phone">
              <button class="btn btn__outlined btn__small relative" v-if="!props.row.sendFollowUp" @click="sendFollowUp(props.row)">
                Welcome Text
              </button>

              <button class="btn btn__outlined btn__small relative" v-if="props.row.sendFollowUp">
                Sent <i class="fa-solid fa-check ml-3" style="color:#00C897;"></i>
              </button>
            </span>
          </span>

          <span v-else-if="props.column.field == 'touch'">

            <span v-if="props.row.touchLog && props.row.touchLog[0]">
              <v-popover>
                <i class="fa-light fa-hand-point-up blueHueDark"></i>
                <template slot="popover">
                  <div>{{findLast(props.row.touchLog).contactedByFirstName}} {{findLast(props.row.touchLog).contactedByLastName}}, {{findLast(props.row.touchLog).method}}:</div>
                  <div>{{findLast(props.row.touchLog).notes}}</div>
                </template>
              </v-popover>
            </span>
          </span>

          <span v-else-if="props.column.field == 'phone'">
                <span v-if="props.row.phone" class="caption">
                  <a :href="'sms:' + props.row.phone" class="darkLink">{{props.row.phone}}</a>
                </span>
              </span>

          <span v-else-if="props.column.field == 'jobs'">

            <span v-if="!props.row.skills || props.row.skills.length == 0">
              <i class="fad fa-briefcase ml-2 mr-2" style="opacity:50%;"></i>
            </span>
            <span v-if="props.row.skills && props.row.skills.length > 0">
              <v-popover>
                <i class="fad fa-briefcase ml-2 mr-2 success"></i>
                <template slot="popover">
                  <span v-for="z in props.row.skills">{{z.title}} / </span>
                </template>
              </v-popover>
            </span>


            <!-- <span v-for="(item, index) in props.row.skills" :key="index">{{item.title}}, </span> -->
          </span>

          <span v-else-if="props.column.field == 'interviewed'">
            <span v-if="props.row.interview && props.row.interview.interviewBy">
              <i class="fas fa-check fa-2x" style="color:green"></i>
            </span>
          </span>
          <span v-else-if="props.column.field == 'link'">
            <router-link :to="`/users/` + props.row.id" target="_blank">
              <i class="fas fa-external-link ml-2 mr-2"></i>
            </router-link>
          </span>


          <span v-else-if="props.column.field == 'workerId'">
   <!--          <div v-if="props.row.workerId && props.row.evereeOnboardingComplete"><i class="fa-solid fa-square-check ml-2 mr-2 success"></i>
            </div> -->

            <button v-if="props.row.evereeOnboardingComplete" class="btn btn__outlined btn__small">
                  Contractor <i class="fa-solid fa-check ml-3" style="color:#00C897;"></i>
                </button>

            <button v-if="!props.row.evereeOnboardingComplete && props.row.updatePayrollReminderSent" class="btn btn__outlined btn__small" @click="onTextPayroll(props.row)">
              Reminder Sent <i class="fa-solid fa-check ml-3" style="color:#00C897;"></i>
            </button>

            <button v-if="!props.row.evereeOnboardingComplete && !props.row.updatePayrollReminderSent" class="btn btn__outlined btn__small" @click="onTextPayroll(props.row)">
              Send Reminder
            </button>
<!-- 
            <button class="btn btn__outlined btn__small relative" v-if="!props.row.sendFollowUp" @click="onTextPayroll(props.row)">
              Payroll Reminder
            </button>

            <button class="btn btn__outlined btn__small relative" v-if="props.row.sendFollowUp">
              Reminder Sent <i class="fa-solid fa-check ml-3" style="color:#00C897;"></i>
            </button>

            <div v-if="props.row.workerId && !props.row.evereeOnboardingComplete"><i class="fa-solid fa-square-check ml-2 mr-2 warning"></i>
            </div>

            <div v-if="!props.row.workerId">
             <i class="fa-solid fa-square-check ml-2 mr-2 danger"></i>
            </div> -->
          </span>
          <span v-else-if="props.column.field == 'resume'">
            <span v-if="props.row.picture">
              <a :href="props.row.picture" target="_blank">
                <i class="fa-regular fa-file ml-3" style="color:#333333;"></i>
              </a>
            </span>
          </span>
          <span v-else-if="props.column.field == 'pipeline'">
            <span v-if="props.row.pipeline && props.row.pipeline.length >= 1">
              <!-- <v-popover>
                <i class="fa-solid fa-filter ml-3" style="color:#1F966F;"></i>
                <template slot="popover" style="display: inline;">
                  <span v-if="props.row.pipelineOrder.title">
                    {{props.row.pipelineOrder.title}}
                  </span>
                </template>
              </v-popover> -->
              <v-popover @apply-hide="clearPopover()" @apply-show="showPipelines(props.row.pipeline)">
                <i class="fa-solid fa-filter ml-3" style="color:#1F966F;"></i>
                <template slot="popover" style="display: inline;">
                  Pipelines:
                  <div v-for="(item, index) in activePipelines">
                    {{item}} 
                  </div>
                </template>
              </v-popover>
            </span>
          </span>


          <span v-else-if="props.column.field == 'groups'">
            <span v-if="props.row.groups && props.row.groups.length >= 1">
              <v-popover @apply-hide="clearTitles()" @apply-show="showPopover(props.row.groups)">
              <i class="fa-solid fa-user-group blueHue ml-2"></i>
              <template slot="popover" style="display: inline;">
                Member Of:
                <div v-for="(item, index) in activeTitles">
                  {{item}} 
                </div>
                </template>
              </v-popover>
            </span>

            <span v-if="props.row.groupApplications && props.row.groupApplications.length >= 1">
              <v-popover @apply-hide="clearGroupTitles()" @apply-show="showPopover(props.row.groupApplications)">
              <i class="fa-solid fa-user-group orangeHue ml-2"></i>
              <template slot="popover" style="display: inline;">
                Applied To:
                <div v-for="(item, index) in activeTitles">
                  {{item}} 
                </div>
                </template>
              </v-popover>
            </span>
          </span>


          <span v-else-if="props.column.field == 'updatedContact'">
            <span v-if="props.row.updatedContact">{{formatDate(props.row.updatedContact)}}</span>
          </span>
          <!-- <span v-else-if="props.column.field == 'state'">
            <input type="text" v-model.trim="props.row.state" placeholder="CA" id="state" />
          </span> -->
          <span v-else-if="props.column.field == 'status'">
            <v-select
              label="status" 
              :options="statuses"
              v-model="props.row.initialContactStatus"
              @input="updateUser(props.row)"
              :clearable="true"
              >
            </v-select>
          </span>

          <span v-else>
            {{props.formattedRow[props.column.field]}}
          </span>
        </template>
      </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import * as moment from 'moment'
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import UserTable from '@/components/UserTable.vue'
import router from '@/router'
const fb = require('../../firebaseConfig.js')

export default {
  name: 'newUsers',
  data: () => ({
    performingRequest: false,
    statuses: ['Contacted', 'Not Contacted', 'Interviewed', 'Contractor Only', 'Onboarding as Employee', 'Hired JES', 'Hide'],
    activeTitles: [],
    activePipelines: [],
    columns: [
      {
        label: '',
        field: 'photoUrl',
        sortable: false,
        width:'42px',
        tdClass: 'text-center',
      },
      {
        label: 'Created',
        field: 'created',
        width: '120px'
      },
      {
        label: 'Send Text',
        field: 'followUp',
        width: '100px',
        sortable: false,
      },
      // {
      //   label: 'Onboarded',
      //   field: 'workerId',
      //   width: '60px',
      //   sortable: false,
      //   tdClass: 'text-center',
      // },
      {
        label: 'Status',
        field: 'status',
        width: '180px'
      },
      {
        label: '',
        field: 'link',
        width: '24px',
        sortable: false,
        tdClass: 'text-center',
      },
      {
        label: '',
        field: 'resume',
        width: '24px',
        sortable: false,
        tdClass: 'text-center',

      },
      {
        label: '',
        field: 'pipeline',
        width: '24px',
        sortable: false,
        tdClass: 'text-center',

      },
      {
        label: '',
        field: 'touch',
        width: '24px',
        sortable: false,
        tdClass: 'text-center',

      },
      {
        label: 'First Name',
        field: 'firstName',
        width: '100px'
      },
      {
        label: 'Last Name',
        field: 'lastName',
        width: '120px'
      },
      
      {
        label: 'Email',
        field: 'email',
      },
      {
        label: 'Phone',
        field: 'phone',
        width: '120px'
      },
      {
        label: 'Skills',
        field: 'jobs',
        sortable: false,
      },
      {
        label: 'Groups',
        field: 'groups',
        sortable: false,
        width: '80px'
      },
      {
        label: 'city',
        field: 'address.city',
        width: '120px'
      },
      {
        label: 'state',
        field: 'address.state',
        width: '40px',
      },
      {
        label: 'Updated By',
        field: 'updatedContactBy',
        width: '120px'
      },
      {
        label: 'Last Updated',
        field: 'updatedContact',
        width: '120px'
      }, 
    ]
  }),
  created () {
    this.$store.dispatch("getNewUsers")
  },
  computed: {
    ...mapState(['newUsers', 'userProfile']),
  },
  components: {
    Loader,
    UserTable
  },
  methods: {
    findLast(touchLog) {
      return touchLog[touchLog.length - 1]
    },
    formatStamp(stamp) {
      if (stamp) {
        const postedDate = new Date(stamp.seconds * 1000);
        return moment(postedDate).format('MM/DD/YYYY')
      }
    },
    formatLast(touchLog) {
      const last = this.findLast(touchLog)
      if (last && last.touched) {
        const postedDate = new Date(last.touched.seconds * 1000);
        return moment(postedDate).format('MM/DD/YYYY')
      } else {
        return null
      }
    },
    onTextPayroll(user) {
      // user.updatePayrollReminderSent = true
      let newText = {
        name: user.firstName,
        phone: user.phone,
        userId: user.id,
        type: 'updatePayroll'
      }
      this.$store.dispatch('updatePayrollText', newText)
      
      // fb.usersCollection.doc(user.id).update({
      //   updatePayrollReminderSent: fb.firestore.FieldValue.serverTimestamp()
      // })
    },
    sendFollowUp(row) {
      console.log(row)
      fb.usersCollection.doc(row.id).update({
        sendFollowUp: true
      })
    },
    sendJobsFollowUp(row) {
      console.log(row)
      fb.usersCollection.doc(row.id).update({
        sendJobsFollowUp: true
      })
    },
    async updateUser(row) {
      const date = new Date()
      let touch = {
        contactedById: this.userProfile.id,
        contactedByFirstName: this.userProfile.firstName,
        contactedByLastName: this.userProfile.lastName,
        method: "Initial Contact",
        notes: '',
        touched: date,
      }
      await this.$store.dispatch("addTouchLog", {
        userId: row.id,
        log: touch
      })

      await fb.usersCollection.doc(row.id).update({
        initialContactStatus: row.initialContactStatus,
        updatedContactBy: this.userProfile.firstName + ' ' + this.userProfile.lastName,
        updatedContact: fb.firestore.FieldValue.serverTimestamp(),
      })
    },
    formatDate(q) {
      if(q) {
        const postedDate = new Date(q.seconds) * 1000;
        return moment(postedDate).format('MMM Do, YYYY')
      } else {
        return null
      }
    },
    clearPopover() {
      return this.activePipelines = []
    },
    clearTitles() {
      return this.activeTitles = []
    },
    clearGroupTitles() {
      return this.activeTitles = []
    },
    showPopover(params){
      console.log(params)
      params.forEach(group => {
        fb.groupsCollection.doc(group).get()
        .then(doc => {
          this.activeTitles.push(doc.data().title)
        })
      })
    },
    showPipelines(params){
      console.log(params)
      params.forEach(group => {
        fb.eventsCollection.doc(group).get()
        .then(doc => {
          this.activePipelines.push(doc.data().title)
        })
      })
    }
  },
  beforeDestroy() {
    this.performingRequest = null
    delete this.performingRequest
    delete this.onTextPayroll
    delete this.formatDate
    delete this.updateUser
    delete this.sendJobsFollowUp
    delete this.onTextPayroll
    delete this.sendFollowUp
    this.$store.dispatch("clearNewUsers")
  }
}
</script>

